import React from 'react';

export default class PingdomAnalytics extends React.Component {
  render() {
    return (
      <script
        src='https://rum-static.pingdom.net/pa-5bb5213c7e84eb00160001cf.js'
        async
      />
    );
  }
}
