export const pageBackground = '#292929';

export const amberHighlightColor = '#FFC200';
export const whiteHighlightColor = '#DDDDDD';

export const lightGreyBackground = '#666666';
export const darkGreyBackground = '#3c3c3c';

export const roundedBoxOutline = {
  backgroundColor: lightGreyBackground,

  border: '1px solid #111111',
  boxShadow: '5px 5px #111111',
  color: '#000000',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14pt',
};

export const dropShadowStyle = {
  border: '1px solid #111111',
  boxShadow: '5px 5px #111111',
  borderRadius: '15px',
};

export const activeButtonStyle = {
  color: pageBackground,
  backgroundColor: amberHighlightColor,
  cursor: 'pointer',
};

export const lightButtonStyle = {
  ...dropShadowStyle,

  backgroundColor: lightGreyBackground,
  color: amberHighlightColor,
  borderRadius: '10px',
  padding: '15px',

  textAlign: 'center',
  textDecoration: 'none',

  ':hover': activeButtonStyle,
};

export const disabledLightButtonStyle = {
  ...lightButtonStyle,
  color: pageBackground,

  ':hover': {
    backgroundColor: lightGreyBackground,
    color: pageBackground,
    cursor: 'default',
  },
};

export const blockSectionStyle = {
  ...dropShadowStyle,

  backgroundColor: darkGreyBackground,
  color: whiteHighlightColor,
};

export const articleStyle = {
  display: 'flex',
  flexDirection: 'column',
  background: '#3c3c3c',
  borderRadius: '25px',
  border: '1px solid #111111',
  boxShadow: '5px 5px #111111',
  color: whiteHighlightColor,

  fontFamily: 'Arial, sans-serif',
  fontSize: '14pt',

  textAlign: 'left',
  padding: '5px 30px 30px 30px',

  '& a': {
    fontWeight: 'bold',
    color: '#FFC200',
    textDecoration: 'none',
    borderRadius: '5px',
    border: '1px solid transparent',
  },

  '& a:hover': {
    backgroundColor: '#FFC200',
    color: '#292929',
    textDecoration: 'none',
    borderRadius: '5px',
    border: '1px solid #FFC200',
  },

  '& a.button': {
    fontWeight: 'bold',
    color: '#FFC200',
    textDecoration: 'none',
    borderRadius: '10px',
    border: '1px solid #111111',
  },

  '& a.button:hover': {
    backgroundColor: '#FFC200',
    color: '#292929',
    textDecoration: 'none',
    borderRadius: '10px',
    border: '1px solid #FFC200',
  },

  '& h1, h1.title': {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '25pt',
    fontWeight: 'bold',
    color: '#FFC200',
  },

  '& h1 + p:first-letter, & h2 + p:first-letter': {
    color: '#FFC200',
    fontSize: '26px',
    fontFamily: 'Raleway, sans-serif',
  },

  '&  h2, & > div.document > div.section > h1': {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '20pt',
    fontWeight: 'bolder',
    fontStyle: 'italic',

    color: whiteHighlightColor,
  },

  '& > ul': {
    listStyle: 'square',
  },
};

export const fancyListCss = {
  '& ul, &': {
    lineHeight: '1.75',
    listStyleType: 'none',
  },
  '& li::before': {
    fontFamily: 'Font Awesome 5 Pro',
    fontWeight: 400,
    content: '✔',
    paddingRight: '10px',
    color: '#00FF00',
  },
  justifyContent: 'stretch',

  display: 'flex',
  flexDirection: 'column',
};
