import React from 'react';
import PropTypes from 'prop-types';

import MainNavButton from './MainNavButton';
import { blockSectionStyle } from './Layout/styles';

export default class WebLayout extends React.Component {
  static get propTypes() {
    return {
      pages: PropTypes.array,
    };
  }

  render() {
    const { pages } = this.props;

    const menuItems = pages.map((page) => {
      const linkIcon =
        Reflect.get(page.node.frontmatter, 'icon') || 'space-shuttle';
      const link = page.node.fields.slug;
      const linkTitle = page.node.frontmatter.title;

      return (
        <MainNavButton
          key={`menu_${link}`}
          link={link}
          icon={linkIcon}
          title={linkTitle}
        />
      );
    });

    menuItems.push(
      <MainNavButton
        key='menu_blog'
        link='/blog'
        icon='pencil-alt'
        title='Blog'
      />
    );

    const navStyle = {
      ...blockSectionStyle,

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',

      flex: 'auto',

      fontWeight: 'bold',
      fontFamily: 'Raleway,SansSerif',
      fontSize: '18px',
      textAlign: 'center',

      marginTop: '12 pt',
      padding: '5px 10px',
    };

    return <nav css={navStyle}>{menuItems}</nav>;
  }
}
