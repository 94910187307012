import React from 'react';
import Helmet from 'react-helmet';

import favicon from './favicon.ico';
import CompanyHeader from '../CompanyHeader';
import NavigationMenu from '../NavigationMenu';
import Footer from '../Footer';
import DripAnalyticsTracker from '../DripAnalyticsTracker';
import PingdomAnalytics from '../PingdomAnalytics';

import fontawesome from '@fortawesome/fontawesome';
import faIcons from '@fortawesome/fontawesome-pro-regular';
import faBrandIcons from '@fortawesome/fontawesome-free-brands';
import { graphql, StaticQuery } from 'gatsby';

import './bootstrapping.css';

fontawesome.library.add(faIcons);
fontawesome.library.add(faBrandIcons);

const PageLayout = ({ data, children }) => {
  const layoutStyle = {
    display: 'flex',
    flexDirection: 'column',

    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    marginRight: '10px',

    '@media screen and (min-width: 1000px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '1000px',
    },
  };

  const siteTitle = data.site.siteMetadata.companyName;
  const siteFooter = data.site.siteMetadata.copyright;

  return (
    <div css={layoutStyle}>
      <Helmet titleTemplate={`${siteTitle} | %s`} defaultTitle={siteTitle}>
        <link rel='icon' type='image/icon' href={favicon} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <script src='https://gumroad.com/js/gumroad.js' />
      </Helmet>

      <CompanyHeader companyName={siteTitle} />
      <NavigationMenu pages={data.allMarkdownRemark.edges} />

      <div css={{marginTop: '10px'}}>{children}</div>

      <Footer footer={siteFooter} />
      <DripAnalyticsTracker />
      <PingdomAnalytics />
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={query}
    render={data => <PageLayout data={data} {...props} /> }
  />
  );


const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        companyName
        author
        copyright
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___order] }
      filter: { frontmatter: { type: { ne: "blog" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            icon
          }
        }
      }
    }  
  }
`;
