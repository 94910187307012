import React from 'react';
import { Link } from 'gatsby';
import FontAwesome from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { lightButtonStyle, activeButtonStyle } from './Layout/styles';

export default class MainNavButton extends React.Component {
  static get propTypes() {
    return {
      icon: PropTypes.string,
      link: PropTypes.string,
      title: PropTypes.string,
    };
  }

  render() {
    const styleCss = {
      ...lightButtonStyle,

      alignSelf: 'stretch',

      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',

      width: '11%',

      margin: '5px',
      flex: 'auto',

      '& span': {
        paddingTop: '5px',
      },

      '@media screen and (max-width: 980px)': {
        flexDirection: 'row',

        width: '28%',
        padding: '15px',

        '& span': {
          paddingLeft: '10px',
        },
      },

      '@media screen and (max-width: 840px)': {
        flexDirection: 'row',

        width: '28%',
        padding: '15px',

        '& span': {
          paddingLeft: '10px',
        },
      },

      '@media screen and (max-width: 620px)': {
        marginLeft: '5px',
        marginRight: '5px',
        flexDirection: 'row',

        '& span': {
          paddingLeft: '10px',
        },
      },

      '@media screen and (max-width: 410px)': {
        marginLeft: '5px',
        marginRight: '5px',
        flexDirection: 'row',
        width: '100%',

        '& span': {
          paddingLeft: '10px',
        },
      },
    };

    return (
      <Link
        key={this.props.link}
        to={this.props.link}
        css={styleCss}
        activeStyle={activeButtonStyle}
      >
        <FontAwesome icon={['far', this.props.icon]} size='lg' />
        <span>{this.props.title}</span>
      </Link>
    );
  }
}
