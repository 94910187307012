import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ReactInterval from 'react-interval';

import companyLogo from './mr-penguin-amber.svg';
import { whiteHighlightColor, amberHighlightColor } from '../Layout/styles';

// TODO: Follow up with interested clients in digital marketing, industry, e-commerce,
// TODO: and machine-learning.
// TODO: Learn the wording from
// Level up your marketing, lead generation, developer outreach, marketing engineering
// using our data source REST APIs, Wordpress to static site migrator, custom sites, ebooks

const OFFERINGS = [
  'strategic roadmaps for scaling existing operations',
  'continuous integration & deployment pipelines',
  'Rust performance enhancements',
  'Dockerization of existing services and apps',
  'API-powered statically generated site migrations',
  'e-courses on Linux/UNIX CLI tools',
  'monolith to microservices migrations',
];

export default class CompanyHeader extends React.Component {
  static get propTypes() {
    return {
      companyName: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      supportItem: 0,
    };
    this.pickRandomOffering = this.pickRandomOffering.bind(this);
  }

  // Level up your marketing, lead generation, developer outreach, marketing engineering
  get startTagline() {
    return 'Level up your DevOps and scale your web apps with';
  }

  // using our data source REST APIs, Wordpress to static site migrator, custom sites, ebooks
  get endTagline() {
    const supportType = OFFERINGS[this.state.supportItem];
    return `${supportType}.`;
  }

  pickRandomOffering() {
    const randomSupportIndex = Math.floor(Math.random() * OFFERINGS.length);
    this.setState({
      supportItem: randomSupportIndex,
    });
  }

  render() {
    let siteTitleElements = [];
    const { companyName } = this.props;

    for (let word of companyName.split(' ')) {
      const firstLetter = word[0];
      const remainder = word.slice(1);
      siteTitleElements.push(
        <span
          key={`mmm_${word}`}
          css={{
            paddingRight: '10px',
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <span css={{ fontSize: '26pt', textDecoration: 'none' }}>
            {firstLetter}
          </span>
          <span>{remainder}</span>
        </span>
      );
    }

    const navStyle = {
      display: 'grid',
      gridTemplateColumns: '0.5fr 0.5fr 2fr 0.5fr',
      gridTemplateRows: '10px 50px 30px 30px 10px',

      alignItems: 'center',

      paddingTop: '5px',
      paddingBottom: '15px',

      '& h1': {
        paddingLeft: '10px',
      },

      '@media screen and (max-width: 860px)': {
        gridTemplateColumns: '0.5fr 0.5fr 2fr 0.5fr',
      },

      '@media screen and (max-width: 660px)': {
        gridTemplateColumns: '0fr 0.5fr 2fr 0fr',
      },

      '@media screen and (max-width: 510px)': {
        display: 'flex',
        flexDirection: 'column',

        '& h1': {
          marginBottom: '10px',
          paddingLeft: 0,
        },

        '& h2': {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    };

    const companyNameStyle = {
      color: amberHighlightColor,

      fontFamily: 'RailwayRegular, sans-serif',
      fontSize: '18pt',
      fontWeight: 'bold',
      fontStyle: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '3px',

      '& div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
    };

    const tagLineStyle = {
      color: whiteHighlightColor,
      fontSize: '12pt',
      fontFamily: 'Consola Mono, monospace',
      gridColumn: '3',
      textAlign: 'center',
    };

    return (
      <Link to='/' css={{ textDecoration: 'none' }}>
        <header css={navStyle}>
          <img
            css={{
              height: '120px',
              justifySelf: 'end',
              gridRow: 'span 4',
              gridColumn: 2,
            }}
            src={companyLogo}
            alt={`${companyName} logo`}
          />
          <h1 css={{ ...companyNameStyle, gridColumn: 3, gridRow: 2 }}>
            <div>{siteTitleElements}</div>
          </h1>
          <h2 css={{ ...tagLineStyle, gridRow: 3 }}>{this.startTagline}</h2>
          <h2 css={{ ...tagLineStyle, gridRow: 4 }}>{this.endTagline}</h2>

          <ReactInterval
            timeout={5000}
            enabled={true}
            callback={this.pickRandomOffering}
          />
        </header>
      </Link>
    );
  }
}
