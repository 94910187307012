import React from 'react';
import PropTypes from 'prop-types';
import { whiteHighlightColor } from './Layout/styles';

export default class Footer extends React.Component {
  static get propTypes() {
    return {
      footer: PropTypes.string,
    };
  }

  render() {
    const footerCss = {
      color: whiteHighlightColor,

      fontFamily: 'Raleway, sans-serif',
      fontSize: 'small',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingTop: '15px',
    };

    return (
      <div>
        <footer css={footerCss}>{this.props.footer}</footer>
      </div>
    );
  }
}
