import React from 'react';

export default class DripAnalyticsTracker extends React.Component {
  render() {
    const DRIP_ACCOUNT = '9816278';
    const trackingScript = `
      var _dcq = _dcq || [];
      var _dcs = _dcs || {};
      _dcs.account = '${DRIP_ACCOUNT}';

      (function() {
        var dc = document.createElement('script');
        dc.type = 'text/javascript'; dc.async = true;
        dc.src = '//tag.getdrip.com/${DRIP_ACCOUNT}.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(dc, s);
      })();
      `;

    return <script dangerouslySetInnerHTML={{ __html: trackingScript }} />;
  }
}
